.drawer {
  width: 240px;
  flex-shrink: 0;

  &__list_wrapper {
    max-width: 240px;
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__accordion_content {
    display: flex!important;
    justify-content: flex-end!important;
  }

  &__link {
    text-decoration: none;
  }

  &__page_name_card {
    min-width: 13rem;
  }

  &__admin_page_name_card {
    min-width: 12rem;
  }
}

.legend {
  &__item {
    display: flex;
    align-items: center;
  }

  &__item_text {
    margin-left: 10px;
  }
}

/* You can add global styles to this file, and also import other style files */

.divider{
    margin-bottom: 10px !important;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.explain{
  font-size: 12px;
  color: gray;
}

.navbar_actions {
  width: 100%;
  display: flex;
}

.select_device {
  &__wrapper {
    width: 180px;
    margin-right: 10px !important;
  }

  &__date_wrapper {
    width: 320px;
  }

  &__org_wrapper {
    width: 180px;
    margin-right: 10px !important;
  }

  &__device_and_date {
    display: flex;
    flex-direction: row;
  }
}

.date_picker_input {
  width: 150px;
  margin-right: 10px !important;

  &.error {
    input {
      color: #d32f2f;
    }
  }

  &__helper_text {
    color: #d32f2f !important;
    position: absolute;
    bottom: -15px;
  }
}

@media (max-width: 900px) {
  .navbar_actions {
    padding: 0 26px;
  }

  .select_device {
    &__wrapper {
      width: 150px;
    }

    &__org_wrapper {
      width: 100%;
    }

    &__device_and_date {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
    }
  }

  .date_picker_input {
    width: 150px;
    margin: 0 0 0 10px !important;

    &__helper_text {
      bottom: 0;
    }
  }
}

@media (max-width: 500px) {
  .select_device {
    &__wrapper {
      width: 150px;

      div {
        height: 45px;
      }

      span {
        font-size: 11px;
      }
    }
  }

  .date_picker_input {
    width: 90px;
    height: 37px;

    input {
      font-size: 11px;
      height: 100%;
    }

    &__helper_text {
      bottom: 2px;
      font-size: 9px !important;
    }
  }
}

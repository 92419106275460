.status {
  &__wrapper {
    min-height: 120px;
    display: grid;
    grid-template-columns: repeat(4, 300px);
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    margin: 20px 10px 12px;

    @media (max-width: 1250px) {
      grid-template-columns: repeat(2, 45%);
    }

    @media (max-width: 650px) {
      grid-template-columns: 100%;
      margin: 20px 0 12px;
    }
  }

  &__name_wrapper {
    display: flex;
    align-items: center;
  }

  &__name {
    margin-left: 6px;
  }
}

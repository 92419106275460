.accordion_summary {
   background-color: #f8f8f8!important;
   border-radius: 4px!important;
   box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%), 0 1px 3px 0px rgb(0 0 0 / 12%);
   width: 100%;
}

.accordion_button {
  &__wrapper {
    display: flex!important;
    justify-content: flex-end!important;
  }
}

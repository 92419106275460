.alerts {
  &__empty_text {
    margin-top: 10px;
    padding-left: 10px;
  }

  &__received_button {
    width: 256px;
    margin: 10px 0!important;
  }

  &__received_button_text {
    color: #fff;
  }
}

.statuses {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;

  @media(max-width: 765px) {
    margin-top: 85px;
  }
}

.device_status {
  display: flex;
  justify-content: space-between;
}

.map {
  height: 48.5vh;
  width: 100%;

  @media (min-width: 1400px) {
    height: 46vh;
  }

  &__wrapper {
    display:flex;
    height: 60vh;
    min-width: 50vw;
  }

  &__actions {
    display: flex ;
    flex-wrap: wrap ;
  }

  &__action_btn {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.organization {
  padding-bottom: 24px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__search_container {
    display: flex;
    width: 70%;
  }

  &__search {
    display: flex;
    align-items: center;
    width: 70%;
    margin-right: 30px;
  }

  &__create_user {
    width: 200px;
  }

  &__select_container {
    display: flex;
  }

  &__search_input {
    margin-left: 5px;
    flex: 1;
  }

  &__select {
    width: 195px;
    margin-right: 20px !important;
    height: 40px;

    div {
      height: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__card_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 0 50px 0 10px;
  }

  &__card {
    margin: 0 !important;
  }

  &__full_name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__select_label {
    display: none !important;
  }

  @media (max-width: 1000px) {
    &__card_list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    margin-top: -20px;

    &__header {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 10px;
    }

    &__select {
      width: 100%;
      margin-right: 0 !important;

      &:first-child {
        margin-right: 10px !important;
      }
    }

    &__search_container {
      display: block;
      width: 100%;
    }

    &__search {
      width: 100%;
      margin-bottom: 10px;
    }

    &__card_list {
      grid-template-columns: 1fr;
      padding: 0 10px;
    }

    &__create_user_wrapper {
      display: flex;
      justify-content: flex-end;
      grid-row-start: 1;
      grid-row-end: 3;
      margin-bottom: 10px;
    }
  }
}

.my_devices {
  &__wrapper {
    p {
      margin: 0 0 10px;
    }
  }

  &__table {
    min-height: 55.8vh;

    p {
      margin-bottom: 17px;
    }
  }

  @media (max-width: 768px) {
    &__wrapper {
      p {
        font-size: 14px;
        margin: 0 0 10px;
      }
    }

    &__table {
      min-height: 50vh;
    }
  }
}

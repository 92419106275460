.temperature {
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: minmax(auto, 350px);

  .add_item {
    margin: 10px 0;
  }

  &__ending {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    grid-template-areas: "a";
    grid-auto-columns: minmax(auto, auto);

    .add_item {
      padding: 10px 0 !important;
    }
  }
}

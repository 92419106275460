.marker_info {
  &__card {
    width: 24rem;
    position: relative;
    left: -3rem;
    z-index: 10;
    cursor: pointer;
  }

  &__header_wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  @media (max-width: 1024px) {
    &__card {
      width: 14rem;
      left: -7rem;
    }

    &__wrapper {
      p {
        font-size: 13px!important;
      }
    }

    &__header_wrapper {
      padding-top: 5px;
    }
  }
}

.manage_devices{
 padding-top: 65px;

  &__tabs_wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 16px;
  }

  &__tab {
    padding: 12px 6px;
  }

  &__tab_panel {
    height: 440px;
    width: 100%;
  }
}


.alerts_info {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  &__alert {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  &__alert_box {
    padding: 1rem 1rem 1rem 0;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.insect_alerts {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  &__card {
    flex: 1 1 0!important;
    height: 100%;
  }
}

.weather_info_card {
  max-width: 50%;
}

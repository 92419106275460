.add_buttons {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 425px) {
  .add_buttons {
    justify-content: center;
  }
}

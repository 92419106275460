.not_found_page {
  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    max-width: 100%;
    max-height: 85vh;
  }
}

.main {
  &__container {
    margin-top: 100px;

    @media (max-width: 900px) {
      &.with_select {
        margin-top: 155px;
      }
    }

    @media (max-width: 500px) {
      &.with_select {
        margin-top: 140px;
      }
    }
  }

  &__progress_wrapper {
    padding: 130px 0 0 0;
  }

  &__progress {
    position: relative;
    width: 15%;
    left: 42%;
    top: 25%;
  }
}

.all_devices {
  &__table {
    min-height: 64vh;

    p {
      margin-bottom: 17px;
    }
  }

  @media (max-width: 768px) {
    &__table {
      p {
        margin-bottom: 15px;
      }
    }
  }
}

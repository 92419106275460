.header {
  display: flex;

  &__toolbar {
    justify-content: space-between;
  }

  &__logo_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__logo {
    width: 128px;
    height: 30px;
    margin-right: 20px;
  }
  &__title {
    width: 100%;
    text-align: end;
  }

  &__burger {
    color: inherit!important;

    &.disabled {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
  }
}

@media (max-width: 900px) {
  .header {
    &__container {
      padding: 10px 0 20px;
    }
    &__title {
      width: 10rem;
    }
  }
}

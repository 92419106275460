.alert_list {
  width: 100%;
  max-width: 600px;
  overflow: auto;

  &__container {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  &__paper {
    background-color: #f5fcf3;

    &.active {
      background-color: #ffdebe;
    }
  }
}

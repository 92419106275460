.add_device {
  padding: 20px;

  .header_text{
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .add_item {
    display: flex;
    align-items: center;
    justify-content: space-between;;
    min-width: 220px;
    max-width: 600px;
    width: 85%;
    flex-wrap: wrap;

    .image {
      width: 52px;
      height: 52px;
      margin: 0 auto;
    }
  }

  .buttons{
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .add_device {
    .buttons{
      justify-content: center;
    }
  }
}


.refill {
  &__wrapper {
    margin: 20px 10px 12px;
    min-height: 70px;
    display: flex;
    align-items: center;
    max-width: 950px;
    justify-content: space-between;
  }

  &__select_wrapper {
    margin: 8px;
    min-width: 270px;
    max-width: 270px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-right: 16px;
  }
}

@media (max-width: 1000px) {
  .refill__wrapper {
    display: block;
  }

  .refill {
    &__select_wrapper {
      max-width: none;
      min-width: auto;
      width: 100%;
      margin-bottom: 20px!important;
    }

    &__item {
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__lifespan {
      max-width: 60px;
    }
  }
}

.serial_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .serial_number {
    flex-direction: column;
    align-items: flex-start;

    span {
      line-height: 100%;
    }
  }
}

.user_modal {
    &__field_container,
    &__select_wrapper,
    &__select_label{
      margin-bottom: 13px;
    }
  
    &__field_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  
    &__last_field {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  
    &__select_label {
      border-bottom: 1px solid #dadada
    }
  }
  
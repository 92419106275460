.card {
  background: #F8F8F8;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 10px 18px;

  &.alert {
    background: #FFD9B6;
  }
}

.card_wrapper__gap {
  margin-top: 20px!important;
}

.loading {
  position: absolute;
  left: 50%;
}

.button {
  &__update {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
  }

  &__loading {
    color: white;
    position: relative;
  }
}

.date_picker_input {
  max-width: 150px;
}

@media (max-width: 1000px) {
  .date_picker_input {
    max-width: 120px;
    min-width: 120px;
  }
}

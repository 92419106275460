$color: rgba(0, 0, 0, 0.12);

.insects {
  &__item {
    margin-top: 10px;
    padding-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $color;
    }
  }

  &__item_wrapper {
    display: flex;
  }

  &__checkbox_wrapper {
    display: flex;
    align-items: center;
  }

  &__threshold {
    display: flex;
  }

  &__threshold_title {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }


  &__threshold_input_wrapper {
    display: flex;
  }

  &__threshold_input {
    width: 50px;
  }
}
